
@font-face {
    font-family: 'Nunito';
    src: url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap');
}

:root {
    --background-color: #161b2d; /* Тёмный фон */
    --text-color: #ffffff; /* Светлый текст */
    --asv-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --asv-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    --asv-gradient: linear-gradient(180deg, hsla(0, 0%, 100%, .15), hsla(0, 0%, 100%, 0));
    --asv-body-font-family: Nunito, sans-serif;
    --asv-body-font-size: 1.0625rem;
    --asv-navbar-brand-font-size: 1.5rem;
    --asv-navbar-toggler-font-size: 1.1875rem;
    --asv-body-font-weight: 400;
    --asv-body-line-height: 1.6;
  }
  
  body {
    background-color: var(--background-color);
    color: var(--text-color);
    font-family: var(--asv-body-font-family);
    font-size: var(--asv-body-font-size);
    font-weight: var(--asv-body-font-weight);
    line-height: var(--asv-body-line-height);
  }

  .bg-asv-dark{
    background-color: #1b2a4e;
  }

  .container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl {
    --av-gutter-x: 2.5rem;
    --av-gutter-y: 0;
    margin-left: auto;
    margin-right: auto;
    padding-left: calc(var(--av-gutter-x)* .5);
    padding-right: calc(var(--av-gutter-x)* .5);
    width: 100%;
  }

  .platforms-container {
    display: inline-flex;
    justify-content: center;
}

.platforms-icon {
    margin-left: .5rem;
    margin-right: .5rem;
    margin-top: .8rem;
    width: 65px;
}

.text-info{
    margin: 5% 0% 0% 0%;
    width: 75%;
    display: flex;
    text-align: center;
    justify-content: center;
    color:#de97ff!important
}

.feature-card {
  height: 100%;
  background-color: #1b2a4e;
  color: #ffffff;
  border: none;
}

.feature-card h3, .feature-card p {
  color: inherit;
}

.app-swiper-background {
  display: flex;
  border-radius: 25px;
  position: relative;
  align-self: center;
  justify-self: center;
  justify-content: center;
  align-items: center;
  margin: 5% 10%;
  background-image: url('../img/swiper-background.png');
  background-size: cover; /* Cover the entire area without repeating */
  background-position: center; /* Center the background image */
}

.app-swiper {
  display: flex;
  border-radius: 25px;
  justify-content: center;
  align-items: center;
}

.app-swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}

.app-swiper-slide-image {
  margin: 6% 10%;
  width: 80%;
  height: 80%;
}

.swiper-pagination {
  position: absolute;
  bottom: 10px; /* Adjust this value to control distance from the bottom */
  left: 0;
  width: 100%;
  text-align: center;
}

.active-language {
  font-weight: bold;
  color: #007bff; /* Bootstrap primary color for demonstration */
}